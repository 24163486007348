/** @jsx jsx */
import { Link } from 'gatsby'
import { Box, Button, Container, Text, Flex, Heading, jsx } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-performance'
import { useLocation, navigate } from '@reach/router'
import { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const ErrorNotFoundPage = () => {
  const translate = useTranslate()
  const { pathname } = useLocation()
  const [match, setMatch] = useState(null)
  useEffect(() => {
    const matches = ['invoices', 'checkouts', 'orders', 'discount']
    for (const path of matches) {
      if (pathname.includes(path)) {
        setMatch(path)
      }
    }
  }, [])

  const messages = {
    invoices: 'Redirecting you to checkout...',
    product: 'Redirecting you to product....',
    checkouts: 'Redirecting you to checkout...',
    orders: 'Redirecting you to your order status...',
    discount: 'Applying discount...'
  }

  const data = useStaticQuery(graphql`
    query {
      products: allContentfulProduct {
        nodes {
          slug
          collection {
            slug
          }
          variants {
            sku
          }
        }
      }
    }
  `)
  const activeProducts = data.products.nodes
    .filter(prod => prod.collection !== null && prod.variants !== null)
    .map(prod => prod.slug)

  const pathArr = pathname.split('/').filter(x => x !== '')
  const prodPathSlug = pathArr[pathArr.length - 1]

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      activeProducts.includes(prodPathSlug)
    ) {
      console.log('navigating to product')
      navigate(`/products/${prodPathSlug}`)
    }
  }, [])

  return (
    <Box>
      <Container>
        <Flex
          sx={{
            alignItems: 'center',
            padding: ['3rem 0', '8rem 0'],
            flexDirection: 'column',
            minHeight: '80vh',
            textAlign: 'center',
            maxWidth: '850px',
            margin: 'auto'
          }}
        >
          <Heading as="h1" variant="h1" sx={{ marginBottom: '2rem' }}>
            {match ? messages[match] : translate('error.not_found.title')}
          </Heading>
          {!match && (
            <Text sx={{ marginBottom: ['2rem', null, '4rem'] }}>
              {translate('error.not_found.text')}{' '}
              <Link to="/" sx={{ textDecoration: 'underline' }}>
                {translate('error.not_found.text_homepage_link')}
              </Link>
              .
            </Text>
          )}

          {match ? (
            <Button
              variant="clear"
              onClick={() => {
                typeof window !== 'undefined' && window.location.reload()
              }}
              sx={{
                textDecoration: 'underline',
                width: 'auto !important',
                height: 'auto !important',
                border: 'none !important'
              }}
            >
              Click here if you are not redirected within a few seconds
            </Button>
          ) : (
            <Button as={Link} to="/all-bikes/">
              {translate('error.not_found.cta')}
            </Button>
          )}
        </Flex>
      </Container>
    </Box>
  )
}

export default ErrorNotFoundPage
